<template>
  <div></div>
</template>

<script>
export default {
  name: "cookie & policy",
};
</script>

<style lang="scss" scoped></style>
